import { cx } from "@emotion/css";
import type React from "react";

import * as styles from "./rich-icon.styles";

import type { IconType } from "~utils/types.js";

export interface RichIconProps {
  Icon?: IconType;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: "muted" | "critical" | "success" | "complementary" | "brand";
  className?: string;
  children?: React.ReactNode;
}

export const RichIcon = ({
  Icon,
  size = "xl",
  variant = "muted",
  className,
  children,
}: RichIconProps) => {
  return (
    <div className={cx(className, styles.root, styles[size], styles[variant])}>
      {Icon ? <Icon /> : children}
    </div>
  );
};
