import { createContext, useEffect, useState } from "react";

import { createNonNullableContextHook } from "~utils/create-non-nullable-context-hook.js";

type ColorModeOverride = "dark" | "light" | null;

type ResolvedColorMode = Exclude<ColorModeOverride, null>;

interface DarkModeContextValue {
  mode: ResolvedColorMode;
  setMode: (mode: ResolvedColorMode) => void;
}

export const DarkModeContext = createContext<DarkModeContextValue | null>(null);

export const useDarkModeContext = createNonNullableContextHook(
  DarkModeContext,
  "DarkModeProvider"
);

interface DarkModeProviderProps {
  children: React.ReactNode;
}

const disableAnimation = () => {
  const style = document.createElement("style");
  style.innerHTML = `
    * {
      transition: none !important;
    }
  `;
  document.head.appendChild(style);
  setTimeout(() => {
    style.remove();
  }, 1000);
};

export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  const systemPreference = window.matchMedia("(prefers-color-scheme: dark)")
    .matches
    ? "dark"
    : "light";
  const [override, setOverride] = useState<ColorModeOverride>(() => {
    return localStorage.getItem("dark-mode-override") as ColorModeOverride;
  });

  const currentMode = override ?? systemPreference;
  useEffect(() => {
    document.body.classList.remove("dark");
    document.body.classList.remove("light");
    document.body.classList.add(currentMode);
  }, [currentMode]);

  const value: DarkModeContextValue = {
    mode: currentMode,
    setMode: (mode: ResolvedColorMode) => {
      disableAnimation();
      const nextMode = mode === systemPreference ? null : mode;
      setOverride(nextMode);
      if (nextMode) {
        localStorage.setItem("dark-mode-override", nextMode);
      } else {
        localStorage.removeItem("dark-mode-override");
      }
    },
  };

  return <DarkModeContext value={value}>{children}</DarkModeContext>;
};
