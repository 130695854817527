import { WrenchIcon } from "lucide-react";
import type React from "react";

import { Container } from "~components/container/container.js";
import { MessageCard } from "~components/message-card/message-card.js";
import type { IconType } from "~utils/types.js";

interface GlobalErrorProps {
  Icon?: IconType;
  title?: string;
  description?: string;
  cta?: React.ReactNode;
}

export const GlobalError = ({
  Icon = WrenchIcon,
  title = "Oops",
  description = "An unexpected error occurred",
  cta,
}: GlobalErrorProps) => {
  return (
    <Container fullHeight verticallyCentered>
      <MessageCard
        Icon={Icon}
        title={title}
        description={description}
        action={cta}
      />
    </Container>
  );
};
