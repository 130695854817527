import { createFileRoute } from "@tanstack/react-router";

import { AuthProvider } from "~features/auth/auth-provider.js";
import { ExtensionPostInstallPage } from "~features/extension/extension-post-install-page/extension-post-install-page.js";
import { TRPCProvider } from "~features/trpc/trpc-provider.js";

export const Route = createFileRoute("/_app/extension/post-install")({
  component: () => {
    return (
      <TRPCProvider>
        <AuthProvider>
          <ExtensionPostInstallPage />
        </AuthProvider>
      </TRPCProvider>
    );
  },
});
