import { TooltipProvider } from "@radix-ui/react-tooltip";
import { createFileRoute, Outlet } from "@tanstack/react-router";

import { ConfirmationProvider } from "~components/dialog/confirmation-provider.js";
import { ToastProvider } from "~components/toast/toast-provider.js";
import { ExtensionInstalledProvider } from "~features/extension/extension-installed-provider/extension-installed-provider.js";
import { TRPCProvider } from "~features/trpc/trpc-provider.js";

export const Route = createFileRoute("/_app")({
  component: () => {
    return (
      <ExtensionInstalledProvider>
        <ToastProvider>
          <ConfirmationProvider>
            <TooltipProvider skipDelayDuration={0} delayDuration={0}>
              <TRPCProvider>
                <Outlet />
              </TRPCProvider>
            </TooltipProvider>
          </ConfirmationProvider>
        </ToastProvider>
      </ExtensionInstalledProvider>
    );
  },
});
