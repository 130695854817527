import { darkColors } from "./colors/dark.js";
import { lightColors } from "./colors/light.js";

export type ThemeColors = typeof lightColors;
export type ThemeColor = keyof ThemeColors;

export const colorToVar = (color: ThemeColor) => `--color-${color}`;

export const color = (color: ThemeColor) => `var(${colorToVar(color)})`;

export const forceColor = (mode: "light" | "dark", color: keyof ThemeColors) =>
  mode === "light" ? lightColors[color] : darkColors[color];

const elevation1 = "rgba(0, 0, 0, 0.03) 0px 1px 2px 0px";
const elevation2 =
  "rgba(0, 0, 0, 0.06) 0px 1px 3px 0px, rgba(0, 0, 0, 0.04) 0px 1px 2px 0px";
const elevation3 =
  "rgba(0, 0, 0, 0.06) 0px 4px 6px -1px, rgba(0, 0, 0, 0.04) 0px 2px 4px -1px";
const elevation4 =
  "rgba(0, 0, 0, 0.06) 0px 10px 15px -3px, rgba(0, 0, 0, 0.03) 0px 4px 6px -2px";
const elevation5 =
  "rgba(0, 0, 0, 0.06) 0px 20px 25px -5px, rgba(0, 0, 0, 0.02) 0px 10px 10px -5px";
const elevation6 = "rgba(0, 0, 0, 0.15) 0px 25px 50px -12px";
const elevation7 =
  "rgba(50, 50, 93, 0.07) 0px 30px 100px -20px,rgba(0, 0, 0, 0.16) 0px 20px 60px -30px";

export const elevation = {
  elevation1,
  elevation2,
  elevation3,
  elevation4,
  elevation5,
  elevation6,
  elevation7,
};

export const controlHeightXs = 24;
export const controlHeightSm = 32;
export const controlHeightMd = 40;
export const controlHeightLg = 56;
export const controlHeightXl = 74;

export const breakpointSmall = 768;

type Breakpoint = "mobile";

const breakpoints: Record<Breakpoint, number> = {
  mobile: 768,
};

export const breakpoint = (from: Breakpoint | null, to: Breakpoint | null) => {
  const parts = [];
  if (from) {
    parts.push(`(min-width: ${breakpoints[from] + 1}px)`);
  }
  if (to) {
    parts.push(`(max-width: ${breakpoints[to]}px)`);
  }
  return `@media screen and ${parts.join(" and ")}`;
};

export const controlHeight = {
  xs: `${controlHeightXs}px`,
  sm: `${controlHeightSm}px`,
  md: `${controlHeightMd}px`,
  lg: `${controlHeightLg}px`,
  xl: `${controlHeightXl}px`,
};

export const fontFamily = {
  default: `var(--font-family-default)`,
  heading: `var(--font-family-heading)`,
  monospace: `var(--font-family-mono)`,
};

const fontWeightBold = 700;
const fontWeightRegular = 400;

export const fontWeight = {
  bold: fontWeightBold,
  regular: fontWeightRegular,
};

const fontLevel1 = "13px";
const fontLevel2 = "14px";
const fontlevel3 = "15px";
const fontlevel4 = "17px";
const fontlevel5 = "20px";
const fontLevel6 = "28px";
const fontLevel7 = "32px";
const fontlevel8 = "40px";
const fontLevel9 = "60px";

const lineHeight1 = "16px";
const lineHeight2 = "20px";
const lineHeight3 = "22px";
const lineHeight4 = "26px";
const lineHeight5 = "28px";
const lineHeight6 = "38px";
const lineHeight7 = "48px";
const lineHeight8 = "60px";
const lineHeight9 = "90px";

export const fontSize = {
  level1: fontLevel1,
  level2: fontLevel2,
  level3: fontlevel3,
  level4: fontlevel4,
  level5: fontlevel5,
  level6: fontLevel6,
  level7: fontLevel7,
  level8: fontlevel8,
  level9: fontLevel9,
};

export const lineHeight = {
  level1: lineHeight1,
  level2: lineHeight2,
  level3: lineHeight3,
  level4: lineHeight4,
  level5: lineHeight5,
  level6: lineHeight6,
  level7: lineHeight7,
  level8: lineHeight8,
  level9: lineHeight9,
};

const bodyLevel1 = `${fontWeight.regular} ${fontSize.level1}/${lineHeight.level1} ${fontFamily.default}`;
const bodyLevel2 = `${fontWeight.regular} ${fontSize.level2}/${lineHeight.level2} ${fontFamily.default}`;
const bodyLevel3 = `${fontWeight.regular} ${fontSize.level3}/${lineHeight.level3} ${fontFamily.default}`;
const bodyLevel4 = `${fontWeight.regular} ${fontSize.level4}/${lineHeight.level4} ${fontFamily.default}`;

const headingLevel1 = `${fontWeight.bold} ${fontSize.level1}/${lineHeight.level1} ${fontFamily.default}`;
const headingLevel2 = `${fontWeight.bold} ${fontSize.level2}/${lineHeight.level2} ${fontFamily.default}`;
const headingLevel3 = `${fontWeight.bold} ${fontSize.level3}/${lineHeight.level3} ${fontFamily.default}`;
const headingLevel4 = `${fontWeight.bold} ${fontSize.level4}/${lineHeight.level4} ${fontFamily.default}`;
const headingLevel5 = `${fontWeight.bold} ${fontSize.level5}/${lineHeight.level5} ${fontFamily.default}`;
const headingLevel6 = `${fontWeight.bold} ${fontSize.level6}/${lineHeight.level6} ${fontFamily.default}`;
const headingLevel7 = `${fontWeight.bold} ${fontSize.level7}/${lineHeight.level7} ${fontFamily.default}`;
const headingLevel8 = `${fontWeight.bold} ${fontSize.level8}/${lineHeight.level8} ${fontFamily.default}`;
const headingLevel9 = `${fontWeight.bold} ${fontSize.level9}/${lineHeight.level9} ${fontFamily.default}`;

const fontCode1 = `${fontWeight.regular} 13px/1.5 ${fontFamily.monospace}`;
const fontCode2 = `${fontWeight.regular} 14px/1.5 ${fontFamily.monospace}`;

export const typography = {
  body1: bodyLevel1,
  body2: bodyLevel2,
  body3: bodyLevel3,
  body4: bodyLevel4,
  heading1: headingLevel1,
  heading2: headingLevel2,
  heading3: headingLevel3,
  heading4: headingLevel4,
  heading5: headingLevel5,
  heading6: headingLevel6,
  heading7: headingLevel7,
  heading8: headingLevel8,
  heading9: headingLevel9,
  code1: fontCode1,
  code2: fontCode2,
};

const radiusSm = "4px";
const radiusMd = "6px";
const radiusLg = "16px";

export const radii = {
  sm: radiusSm,
  md: radiusMd,
  lg: radiusLg,
};

export const space = (multiplier: number) => `${multiplier * 8}px`;

export const zIndex = {
  teamFooter: 10,
  dialog: 50,
  popover: 100,
  toast: 200,
};
