import { createFileRoute } from "@tanstack/react-router";

import { AuthProvider } from "~features/auth/auth-provider.js";
import AcceptInvitation from "~features/invitations/accept-invitation/accept-invitation.js";
import { PageLoader } from "~features/page-loader/page-loader.js";
import { TRPCProvider } from "~features/trpc/trpc-provider.js";

export const Route = createFileRoute("/invitation/$membershipId")({
  pendingComponent: PageLoader,
  component: () => (
    <TRPCProvider>
      <AuthProvider>
        <AcceptInvitation />
      </AuthProvider>
    </TRPCProvider>
  ),
});
