import { css } from "@emotion/css";

import { color, controlHeight } from "~styles";

export const root = css`
  display: inline-flex;
  width: var(--rich-icon-size);
  height: var(--rich-icon-size);
  background-color: var(--rich-icon-background);
  color: var(--rich-icon-color);
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  > svg {
    width: var(--rich-icon-icon-size);
    height: var(--rich-icon-icon-size);
  }
`;

export const xs = css`
  --rich-icon-size: ${controlHeight.xs};
  --rich-icon-icon-size: calc(${controlHeight.xs} / 2);
  font-size: 13px;
`;

export const sm = css`
  --rich-icon-size: ${controlHeight.sm};
  --rich-icon-icon-size: calc(${controlHeight.sm} / 2);
`;

export const md = css`
  --rich-icon-size: ${controlHeight.md};
  --rich-icon-icon-size: calc(${controlHeight.md} / 2);
`;

export const lg = css`
  --rich-icon-size: ${controlHeight.lg};
  --rich-icon-icon-size: calc(${controlHeight.lg} / 2);
`;

export const xl = css`
  --rich-icon-size: ${controlHeight.xl};
  --rich-icon-icon-size: calc(${controlHeight.xl} / 2);
`;

export const critical = css`
  --rich-icon-background: ${color("criticalMuted")};
  --rich-icon-color: ${color("textCritical")};
`;

export const muted = css`
  --rich-icon-background: ${color("muted")};
  --rich-icon-color: ${color("textSecondary")};
`;

export const success = css`
  --rich-icon-background: ${color("success")};
  --rich-icon-color: ${color("textOnSuccess")};
`;

export const complementary = css`
  --rich-icon-background: ${color("complementary")};
  --rich-icon-color: ${color("textOnComplementary")};
`;

export const brand = css`
  --rich-icon-background: ${color("brand")};
  --rich-icon-color: ${color("textOnBrand")};
`;
