import { RouterProvider, createRouter } from "@tanstack/react-router";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";

// Import the generated route tree
import { routeTree } from "./routeTree.gen";

import { GlobalLoader } from "~features/global-loader/global-loader.js";

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  defaultPendingComponent: GlobalLoader,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

// Render the app
const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <Suspense fallback="Loading...">
      <RouterProvider router={router} />
    </Suspense>
  );
}
