import { createDomSnapshot } from "@capture/snapshot";
import { takeScreenshot } from "./take-screenshot.js";
export class BrowserScreenshotTaker {
    events = [];
    canCaptureArea = () => {
        return navigator.userAgent.toLowerCase().includes("chrome");
    };
    takeScreenshot = async () => {
        let dataUrl;
        try {
            dataUrl = await takeScreenshot();
        }
        catch (error) {
            if (typeof navigator.mediaDevices.getDisplayMedia !== "function") {
                // Screenshotting isn't possible - fallback to the DOM snapshot
            }
            else {
                throw error;
            }
        }
        const tabWidth = window.innerWidth;
        const tabHeight = window.innerHeight;
        return {
            tabWidth,
            tabHeight,
            dataUrl,
            snapshot: createDomSnapshot(),
            events: this.events,
        };
    };
    logEvent(event) {
        this.events.push(event);
        // Only store the last 5 minutes of events
        const maxTime = Date.now() - 60 * 1000 * 5;
        let eventsToTrim = 0;
        for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].time < maxTime) {
                eventsToTrim += 1;
            }
            else {
                break;
            }
        }
        if (eventsToTrim > 0) {
            this.events = this.events.slice(eventsToTrim);
        }
    }
}
