import { cx } from "@emotion/css";

import * as styles from "./spinner.styles";

interface SpinnerProps {
  className?: string;
  size?: number | string;
}

export const Spinner = ({ className, size = 16 }: SpinnerProps) => {
  return (
    <span
      style={{ width: `${size}px`, height: `${size}px` }}
      className={cx(styles.spinner, className)}
    >
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
      <span className={styles.leaf} />
    </span>
  );
};
