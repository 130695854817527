import { createFileRoute } from "@tanstack/react-router";

import { FolderPageLoading } from "~features/folder-page/folder-page-loading.js";
import { FolderPage } from "~features/folder-page/folder-page.js";
import { HomeLayoutUpgradeView } from "~features/home-layout/home-layout-upgrade-view.js";
import { RequireSubscription } from "~features/teams/require-subscription.js";

export const Route = createFileRoute(
  "/_app/$teamId/_dashboard/_home/folder/$folderId"
)({
  pendingComponent: FolderPageLoading,

  component: () => (
    <RequireSubscription fallback={<HomeLayoutUpgradeView />}>
      <FolderPage />
    </RequireSubscription>
  ),
});
