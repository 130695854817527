import { createFileRoute, Navigate } from "@tanstack/react-router";

import { useAuthContext } from "~features/auth/auth-context.js";
import { AuthProvider } from "~features/auth/auth-provider.js";
import { TRPCProvider } from "~features/trpc/trpc-provider.js";
import { getLastKnownTeamId } from "~utils/get-last-known-team-id.js";

const TeamRedirect = () => {
  const auth = useAuthContext();

  if (!auth) {
    return <Navigate to="/auth/login" replace />;
  }

  const lastKnownTeamId = getLastKnownTeamId();
  const match = auth?.teams.find((team) => team.id === lastKnownTeamId);
  const teamId = match?.id || auth?.teams[0].id;

  if (!teamId) {
    throw new Error("No team found");
  }

  return <Navigate to="/$teamId" params={{ teamId }} replace />;
};

const Index = () => {
  return (
    <TRPCProvider>
      <AuthProvider>
        <TeamRedirect />
      </AuthProvider>
    </TRPCProvider>
  );
};

export const Route = createFileRoute("/")({
  component: Index,
});
