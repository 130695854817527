import type { UseButtonProps } from "./use-button.js";
import { useButton } from "./use-button.js";

export type ButtonProps = UseButtonProps<
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick">
> & {
  ref?: React.Ref<HTMLButtonElement>;
};

export const Button = ({ ref, ...props }: ButtonProps) => {
  return <button {...useButton(props)} ref={ref} />;
};
