import chroma from "chroma-js";

import type { ThemeColors } from "../constants.js";

const brand = chroma("14FC84");

const tint = "#2e6ffc";

const greyScale = chroma.scale(["#111111", "#ffffff"]);

const primary = greyScale(0.03).mix(tint, 0.0075);
const secondary = greyScale(0.05).mix(tint, 0.0075);
const tertiary = greyScale(0.02).mix(tint, 0.0075);
const contrast = greyScale(1);

const accent = "#BDEE63";
const complementary = chroma("#2e6ffc");

const muted = greyScale(0.2).mix(tint, 0.1).alpha(0.4);
const overlay = "#00000080";

const backgroundSkeleton = "#e8ebf0";

const success = chroma("#3E9B4F");
const warning = chroma("#f59e0c");
const critical = chroma("#dc2625");

const textWarning = chroma("#d9760c");

export const darkColors = {
  brand: brand.hex(),
  brandHover: brand.brighten(0.4).hex(),
  brandLight: brand.alpha(0.8).hex(),
  textOnBrand: brand.darken(5).hex(),
  primary: primary.hex(),
  primaryHover: primary.brighten(0.1).hex(),
  primaryBorder: primary.brighten(0.5).hex(),
  secondary: secondary.hex(),
  secondaryHover: secondary.brighten(0.2).hex(),
  secondaryBorder: secondary.brighten(0.7).hex(),
  muted: muted.hex(),
  mutedHover: muted.brighten(0.5).hex(),
  scrollbar: greyScale(0.2).mix(tint, 0.1).alpha(0.4).hex(),
  tertiary: tertiary.hex(),
  tertiaryHover: tertiary.brighten(0.01).hex(),
  tertiaryBorder: tertiary.brighten(0.7).hex(),
  overlay,
  textPrimary: greyScale(1).hex(),
  textSecondary: greyScale(0.6).mix(tint, 0.1).hex(),
  textInverse: greyScale(0).hex(),
  textInverseSecondary: greyScale(0.2).hex(),
  textPlaceholder: greyScale(0.6).mix(tint, 0.1).hex(),
  textDisabled: greyScale(0.2).hex(),
  accent,
  complementary: complementary.hex(),
  complementaryHover: complementary.brighten(0.01).hex(),
  textOnComplementary: "#ffffff",
  success: success.hex(),
  successMuted: success.alpha(0.1).hex(),
  textSuccess: success.brighten(0.5).hex(),
  textOnSuccess: "#ffffff",
  contrast: contrast.hex(),
  contrastHover: contrast.darken(0.4).hex(),
  backgroundSkeleton,
  warning: warning.hex(),
  warningMuted: warning.alpha(0.1).hex(),
  warningMutedHover: warning.alpha(0.2).hex(),
  textWarning: textWarning.brighten(0.8).hex(),
  textWarningSecondary: textWarning.hex(),
  critical: critical.hex(),
  textOnCritical: "#ffffff",
  criticalMuted: critical.alpha(0.1).hex(),
  criticalMutedHover: critical.alpha(0.2).hex(),
  criticalHover: critical.brighten(0.2).hex(),
  criticalMutedBorder: critical.alpha(0.5).hex(),
  textCritical: critical.brighten(0.8).hex(),
  textCriticalSecondary: critical.hex(),

  codeTag: "#59A6C9",
  codeName: "#59A6C9",
  codeKey: "#9ABBDB",
  codeValue: "#F29766",
  codeComment: "#848484",
  codeDocType: "#B7B7B7",

  objectLabel: "#C8C8C8",
  objectText: "#EDEEEE",
  objectKey: "#F6D9F8",
  objectValue: "#C8C8C8",
  objectString: "#EE8953",
  objectRegex: "#EE8953",
  objectError: "#FD7E7E",
  objectBoolean: "#987FFD",
  objectNumber: "#987FFD",
  objectUndefined: "#848484",
  objectNull: "#848484",
  objectPrototype: "#AB98AC",
  objectFunction: "#EDEEEE",
  objectFunctionDecorator: "#D0D6FB",

  iconRequest: "#DB732E",
  iconStyle: "#A314ED",
  iconScript: "#E49562",
  iconFont: "#387D9E",
  iconMedia: "#44A647",
  iconDocument: "#737EF3",
} satisfies ThemeColors;
