import * as styles from "./global-loader.styles.js";

import { Spinner } from "~components/spinner/spinner.js";


export const GlobalLoader = () => {
  return (
    <div className={styles.root}>
      <Spinner size={24} />
    </div>
  );
};
