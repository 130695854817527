import { createInstance, Identify } from "@amplitude/analytics-browser";
import { createContext, useEffect, useMemo } from "react";

import { config } from "../config.js";

import { createNonNullableContextHook } from "./create-non-nullable-context-hook.js";

type AmplitudeClient = ReturnType<typeof createInstance>;
const deviceId =
  new URLSearchParams(window.location.search).get("deviceId") ?? undefined;

export const AmplitudeContext = createContext<AmplitudeClient | null>(null);
export const useAmplitudeContext = createNonNullableContextHook(
  AmplitudeContext,
  "AmplitudeProvider"
);

interface AmplitudeProviderProps {
  children: React.ReactNode;
  enableSessionReplay?: boolean;
}

export const AmplitudeProvider = ({
  enableSessionReplay = false,
  children,
}: AmplitudeProviderProps) => {
  const amplitude = useMemo(() => createInstance(), []);

  useEffect(() => {
    amplitude.init(config.AMPLITUDE_API_KEY as string, {
      serverUrl: `${config.SERVER_ORIGIN}/amplitude`,
      autocapture: {
        pageViews: true,
        attribution: true,
        elementInteractions: true,
      },
      deviceId,
    });
    if (enableSessionReplay && config.STAGE === "production") {
      import("@amplitude/plugin-session-replay-browser")
        .then(({ sessionReplayPlugin }) => {
          const sessionReplayTracking = sessionReplayPlugin();
          amplitude.add(sessionReplayTracking);
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    }
    const search = new URLSearchParams(window.location.search);
    const ref = search.get("ref");
    const identify = new Identify();
    identify.setOnce("initial_visit_url", window.location.href);
    if (ref) {
      identify.setOnce("initial_url_ref", ref);
    }
    amplitude.identify(identify);
  }, [amplitude, enableSessionReplay]);

  return <AmplitudeContext value={amplitude}>{children}</AmplitudeContext>;
};
