/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TestImport } from './routes/test'
import { Route as AppRouteImport } from './routes/_app/route'
import { Route as IndexImport } from './routes/index'
import { Route as WidgetPreviewImport } from './routes/widget/preview'
import { Route as InvitationMembershipIdImport } from './routes/invitation/$membershipId'
import { Route as AppTeamIdRouteImport } from './routes/_app/$teamId/route'
import { Route as AppTSplatImport } from './routes/_app/t/$'
import { Route as AppExtensionPostInstallImport } from './routes/_app/extension/post-install'
import { Route as AppAuthLoginImport } from './routes/_app/auth/login'
import { Route as AppTeamIdSwitchTeamImport } from './routes/_app/$teamId/switch-team'
import { Route as AppRShareIdRouteImport } from './routes/_app/r/$shareId/route'
import { Route as AppTeamIdDashboardRouteImport } from './routes/_app/$teamId/_dashboard/route'
import { Route as AppRShareIdIndexImport } from './routes/_app/r/$shareId/index'
import { Route as AppRShareIdTimelineImport } from './routes/_app/r/$shareId/timeline'
import { Route as AppRShareIdInspectImport } from './routes/_app/r/$shareId/inspect'
import { Route as AppTeamIdSetupSetupRouteImport } from './routes/_app/$teamId/setup/_setup/route'
import { Route as AppRShareIdNetworkIndexImport } from './routes/_app/r/$shareId/network.index'
import { Route as AppTeamIdSetupSetupIndexImport } from './routes/_app/$teamId/setup/_setup/index'
import { Route as AppTeamIdDashboardTeamIndexImport } from './routes/_app/$teamId/_dashboard/team/index'
import { Route as AppTeamIdDashboardSubscriptionIndexImport } from './routes/_app/$teamId/_dashboard/subscription/index'
import { Route as AppTeamIdDashboardIntegrationsIndexImport } from './routes/_app/$teamId/_dashboard/integrations/index'
import { Route as AppTeamIdDashboardHomeIndexImport } from './routes/_app/$teamId/_dashboard/_home/index'
import { Route as AppRShareIdNetworkEventIdImport } from './routes/_app/r/$shareId/network.$eventId'
import { Route as AppTeamIdSetupSetupProfileImport } from './routes/_app/$teamId/setup/_setup/profile'
import { Route as AppTeamIdSetupSetupInstallImport } from './routes/_app/$teamId/setup/_setup/install'
import { Route as AppTeamIdSetupSetupEmbedTestImport } from './routes/_app/$teamId/setup/_setup/embed-test'
import { Route as AppTeamIdSetupSetupEmbedImport } from './routes/_app/$teamId/setup/_setup/embed'
import { Route as AppTeamIdDashboardAccountSettingsImport } from './routes/_app/$teamId/_dashboard/account/settings'
import { Route as AppTeamIdSetupIntegrationIdentifierRouteImport } from './routes/_app/$teamId/setup/integration/$identifier/route'
import { Route as AppTeamIdSetupIntegrationIdentifierIndexImport } from './routes/_app/$teamId/setup/integration/$identifier/$index'
import { Route as AppTeamIdSetupSetupExtensionIndexImport } from './routes/_app/$teamId/setup/_setup/extension/index'
import { Route as AppTeamIdSetupIntegrationIdentifierTestImport } from './routes/_app/$teamId/setup/integration/$identifier/test'
import { Route as AppTeamIdSetupIntegrationIdentifierConnectImport } from './routes/_app/$teamId/setup/integration/$identifier/connect'
import { Route as AppTeamIdSetupSetupExtensionCompleteImport } from './routes/_app/$teamId/setup/_setup/extension/complete'
import { Route as AppTeamIdDashboardIntegrationsIdentifierEditImport } from './routes/_app/$teamId/_dashboard/integrations/$identifier/edit'
import { Route as AppTeamIdDashboardHomeFolderFolderIdImport } from './routes/_app/$teamId/_dashboard/_home/folder/$folderId'

// Create Virtual Routes

const AppTeamIdSetupImport = createFileRoute('/_app/$teamId/setup')()

// Create/Update Routes

const TestRoute = TestImport.update({
  id: '/test',
  path: '/test',
  getParentRoute: () => rootRoute,
} as any)

const AppRouteRoute = AppRouteImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WidgetPreviewRoute = WidgetPreviewImport.update({
  id: '/widget/preview',
  path: '/widget/preview',
  getParentRoute: () => rootRoute,
} as any)

const InvitationMembershipIdRoute = InvitationMembershipIdImport.update({
  id: '/invitation/$membershipId',
  path: '/invitation/$membershipId',
  getParentRoute: () => rootRoute,
} as any)

const AppTeamIdRouteRoute = AppTeamIdRouteImport.update({
  id: '/$teamId',
  path: '/$teamId',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppTeamIdSetupRoute = AppTeamIdSetupImport.update({
  id: '/setup',
  path: '/setup',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTSplatRoute = AppTSplatImport.update({
  id: '/t/$',
  path: '/t/$',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppExtensionPostInstallRoute = AppExtensionPostInstallImport.update({
  id: '/extension/post-install',
  path: '/extension/post-install',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppAuthLoginRoute = AppAuthLoginImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppTeamIdSwitchTeamRoute = AppTeamIdSwitchTeamImport.update({
  id: '/switch-team',
  path: '/switch-team',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppRShareIdRouteRoute = AppRShareIdRouteImport.update({
  id: '/r/$shareId',
  path: '/r/$shareId',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppTeamIdDashboardRouteRoute = AppTeamIdDashboardRouteImport.update({
  id: '/_dashboard',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppRShareIdIndexRoute = AppRShareIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppRShareIdTimelineRoute = AppRShareIdTimelineImport.update({
  id: '/timeline',
  path: '/timeline',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppRShareIdInspectRoute = AppRShareIdInspectImport.update({
  id: '/inspect',
  path: '/inspect',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdSetupSetupRouteRoute = AppTeamIdSetupSetupRouteImport.update({
  id: '/_setup',
  getParentRoute: () => AppTeamIdSetupRoute,
} as any)

const AppRShareIdNetworkIndexRoute = AppRShareIdNetworkIndexImport.update({
  id: '/network/',
  path: '/network/',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdSetupSetupIndexRoute = AppTeamIdSetupSetupIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
} as any)

const AppTeamIdDashboardTeamIndexRoute =
  AppTeamIdDashboardTeamIndexImport.update({
    id: '/team/',
    path: '/team/',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppTeamIdDashboardSubscriptionIndexRoute =
  AppTeamIdDashboardSubscriptionIndexImport.update({
    id: '/subscription/',
    path: '/subscription/',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppTeamIdDashboardIntegrationsIndexRoute =
  AppTeamIdDashboardIntegrationsIndexImport.update({
    id: '/integrations/',
    path: '/integrations/',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppTeamIdDashboardHomeIndexRoute =
  AppTeamIdDashboardHomeIndexImport.update({
    id: '/_home/',
    path: '/',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppRShareIdNetworkEventIdRoute = AppRShareIdNetworkEventIdImport.update({
  id: '/network/$eventId',
  path: '/network/$eventId',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdSetupSetupProfileRoute = AppTeamIdSetupSetupProfileImport.update(
  {
    id: '/profile',
    path: '/profile',
    getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
  } as any,
)

const AppTeamIdSetupSetupInstallRoute = AppTeamIdSetupSetupInstallImport.update(
  {
    id: '/install',
    path: '/install',
    getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
  } as any,
)

const AppTeamIdSetupSetupEmbedTestRoute =
  AppTeamIdSetupSetupEmbedTestImport.update({
    id: '/embed-test',
    path: '/embed-test',
    getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
  } as any)

const AppTeamIdSetupSetupEmbedRoute = AppTeamIdSetupSetupEmbedImport.update({
  id: '/embed',
  path: '/embed',
  getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
} as any)

const AppTeamIdDashboardAccountSettingsRoute =
  AppTeamIdDashboardAccountSettingsImport.update({
    id: '/account/settings',
    path: '/account/settings',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppTeamIdSetupIntegrationIdentifierRouteRoute =
  AppTeamIdSetupIntegrationIdentifierRouteImport.update({
    id: '/integration/$identifier',
    path: '/integration/$identifier',
    getParentRoute: () => AppTeamIdSetupRoute,
  } as any)

const AppTeamIdSetupIntegrationIdentifierIndexRoute =
  AppTeamIdSetupIntegrationIdentifierIndexImport.update({
    id: '/$index',
    path: '/$index',
    getParentRoute: () => AppTeamIdSetupIntegrationIdentifierRouteRoute,
  } as any)

const AppTeamIdSetupSetupExtensionIndexRoute =
  AppTeamIdSetupSetupExtensionIndexImport.update({
    id: '/extension/',
    path: '/extension/',
    getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
  } as any)

const AppTeamIdSetupIntegrationIdentifierTestRoute =
  AppTeamIdSetupIntegrationIdentifierTestImport.update({
    id: '/test',
    path: '/test',
    getParentRoute: () => AppTeamIdSetupIntegrationIdentifierRouteRoute,
  } as any)

const AppTeamIdSetupIntegrationIdentifierConnectRoute =
  AppTeamIdSetupIntegrationIdentifierConnectImport.update({
    id: '/connect',
    path: '/connect',
    getParentRoute: () => AppTeamIdSetupIntegrationIdentifierRouteRoute,
  } as any)

const AppTeamIdSetupSetupExtensionCompleteRoute =
  AppTeamIdSetupSetupExtensionCompleteImport.update({
    id: '/extension/complete',
    path: '/extension/complete',
    getParentRoute: () => AppTeamIdSetupSetupRouteRoute,
  } as any)

const AppTeamIdDashboardIntegrationsIdentifierEditRoute =
  AppTeamIdDashboardIntegrationsIdentifierEditImport.update({
    id: '/integrations/$identifier/edit',
    path: '/integrations/$identifier/edit',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

const AppTeamIdDashboardHomeFolderFolderIdRoute =
  AppTeamIdDashboardHomeFolderFolderIdImport.update({
    id: '/_home/folder/$folderId',
    path: '/folder/$folderId',
    getParentRoute: () => AppTeamIdDashboardRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppRouteImport
      parentRoute: typeof rootRoute
    }
    '/test': {
      id: '/test'
      path: '/test'
      fullPath: '/test'
      preLoaderRoute: typeof TestImport
      parentRoute: typeof rootRoute
    }
    '/_app/$teamId': {
      id: '/_app/$teamId'
      path: '/$teamId'
      fullPath: '/$teamId'
      preLoaderRoute: typeof AppTeamIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/invitation/$membershipId': {
      id: '/invitation/$membershipId'
      path: '/invitation/$membershipId'
      fullPath: '/invitation/$membershipId'
      preLoaderRoute: typeof InvitationMembershipIdImport
      parentRoute: typeof rootRoute
    }
    '/widget/preview': {
      id: '/widget/preview'
      path: '/widget/preview'
      fullPath: '/widget/preview'
      preLoaderRoute: typeof WidgetPreviewImport
      parentRoute: typeof rootRoute
    }
    '/_app/$teamId/_dashboard': {
      id: '/_app/$teamId/_dashboard'
      path: ''
      fullPath: '/$teamId'
      preLoaderRoute: typeof AppTeamIdDashboardRouteImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/r/$shareId': {
      id: '/_app/r/$shareId'
      path: '/r/$shareId'
      fullPath: '/r/$shareId'
      preLoaderRoute: typeof AppRShareIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/$teamId/switch-team': {
      id: '/_app/$teamId/switch-team'
      path: '/switch-team'
      fullPath: '/$teamId/switch-team'
      preLoaderRoute: typeof AppTeamIdSwitchTeamImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/auth/login': {
      id: '/_app/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AppAuthLoginImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/extension/post-install': {
      id: '/_app/extension/post-install'
      path: '/extension/post-install'
      fullPath: '/extension/post-install'
      preLoaderRoute: typeof AppExtensionPostInstallImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/t/$': {
      id: '/_app/t/$'
      path: '/t/$'
      fullPath: '/t/$'
      preLoaderRoute: typeof AppTSplatImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/$teamId/setup': {
      id: '/_app/$teamId/setup'
      path: '/setup'
      fullPath: '/$teamId/setup'
      preLoaderRoute: typeof AppTeamIdSetupImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/setup/_setup': {
      id: '/_app/$teamId/setup/_setup'
      path: '/setup'
      fullPath: '/$teamId/setup'
      preLoaderRoute: typeof AppTeamIdSetupSetupRouteImport
      parentRoute: typeof AppTeamIdSetupRoute
    }
    '/_app/r/$shareId/inspect': {
      id: '/_app/r/$shareId/inspect'
      path: '/inspect'
      fullPath: '/r/$shareId/inspect'
      preLoaderRoute: typeof AppRShareIdInspectImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/r/$shareId/timeline': {
      id: '/_app/r/$shareId/timeline'
      path: '/timeline'
      fullPath: '/r/$shareId/timeline'
      preLoaderRoute: typeof AppRShareIdTimelineImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/r/$shareId/': {
      id: '/_app/r/$shareId/'
      path: '/'
      fullPath: '/r/$shareId/'
      preLoaderRoute: typeof AppRShareIdIndexImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/setup/integration/$identifier': {
      id: '/_app/$teamId/setup/integration/$identifier'
      path: '/integration/$identifier'
      fullPath: '/$teamId/setup/integration/$identifier'
      preLoaderRoute: typeof AppTeamIdSetupIntegrationIdentifierRouteImport
      parentRoute: typeof AppTeamIdSetupImport
    }
    '/_app/$teamId/_dashboard/account/settings': {
      id: '/_app/$teamId/_dashboard/account/settings'
      path: '/account/settings'
      fullPath: '/$teamId/account/settings'
      preLoaderRoute: typeof AppTeamIdDashboardAccountSettingsImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/setup/_setup/embed': {
      id: '/_app/$teamId/setup/_setup/embed'
      path: '/embed'
      fullPath: '/$teamId/setup/embed'
      preLoaderRoute: typeof AppTeamIdSetupSetupEmbedImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/$teamId/setup/_setup/embed-test': {
      id: '/_app/$teamId/setup/_setup/embed-test'
      path: '/embed-test'
      fullPath: '/$teamId/setup/embed-test'
      preLoaderRoute: typeof AppTeamIdSetupSetupEmbedTestImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/$teamId/setup/_setup/install': {
      id: '/_app/$teamId/setup/_setup/install'
      path: '/install'
      fullPath: '/$teamId/setup/install'
      preLoaderRoute: typeof AppTeamIdSetupSetupInstallImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/$teamId/setup/_setup/profile': {
      id: '/_app/$teamId/setup/_setup/profile'
      path: '/profile'
      fullPath: '/$teamId/setup/profile'
      preLoaderRoute: typeof AppTeamIdSetupSetupProfileImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/r/$shareId/network/$eventId': {
      id: '/_app/r/$shareId/network/$eventId'
      path: '/network/$eventId'
      fullPath: '/r/$shareId/network/$eventId'
      preLoaderRoute: typeof AppRShareIdNetworkEventIdImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/_dashboard/_home/': {
      id: '/_app/$teamId/_dashboard/_home/'
      path: '/'
      fullPath: '/$teamId/'
      preLoaderRoute: typeof AppTeamIdDashboardHomeIndexImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/_dashboard/integrations/': {
      id: '/_app/$teamId/_dashboard/integrations/'
      path: '/integrations'
      fullPath: '/$teamId/integrations'
      preLoaderRoute: typeof AppTeamIdDashboardIntegrationsIndexImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/_dashboard/subscription/': {
      id: '/_app/$teamId/_dashboard/subscription/'
      path: '/subscription'
      fullPath: '/$teamId/subscription'
      preLoaderRoute: typeof AppTeamIdDashboardSubscriptionIndexImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/_dashboard/team/': {
      id: '/_app/$teamId/_dashboard/team/'
      path: '/team'
      fullPath: '/$teamId/team'
      preLoaderRoute: typeof AppTeamIdDashboardTeamIndexImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/setup/_setup/': {
      id: '/_app/$teamId/setup/_setup/'
      path: '/'
      fullPath: '/$teamId/setup/'
      preLoaderRoute: typeof AppTeamIdSetupSetupIndexImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/r/$shareId/network/': {
      id: '/_app/r/$shareId/network/'
      path: '/network'
      fullPath: '/r/$shareId/network'
      preLoaderRoute: typeof AppRShareIdNetworkIndexImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/_dashboard/_home/folder/$folderId': {
      id: '/_app/$teamId/_dashboard/_home/folder/$folderId'
      path: '/folder/$folderId'
      fullPath: '/$teamId/folder/$folderId'
      preLoaderRoute: typeof AppTeamIdDashboardHomeFolderFolderIdImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/_dashboard/integrations/$identifier/edit': {
      id: '/_app/$teamId/_dashboard/integrations/$identifier/edit'
      path: '/integrations/$identifier/edit'
      fullPath: '/$teamId/integrations/$identifier/edit'
      preLoaderRoute: typeof AppTeamIdDashboardIntegrationsIdentifierEditImport
      parentRoute: typeof AppTeamIdDashboardRouteImport
    }
    '/_app/$teamId/setup/_setup/extension/complete': {
      id: '/_app/$teamId/setup/_setup/extension/complete'
      path: '/extension/complete'
      fullPath: '/$teamId/setup/extension/complete'
      preLoaderRoute: typeof AppTeamIdSetupSetupExtensionCompleteImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
    '/_app/$teamId/setup/integration/$identifier/$index': {
      id: '/_app/$teamId/setup/integration/$identifier/$index'
      path: '/$index'
      fullPath: '/$teamId/setup/integration/$identifier/$index'
      preLoaderRoute: typeof AppTeamIdSetupIntegrationIdentifierIndexImport
      parentRoute: typeof AppTeamIdSetupIntegrationIdentifierRouteImport
    }
    '/_app/$teamId/setup/integration/$identifier/connect': {
      id: '/_app/$teamId/setup/integration/$identifier/connect'
      path: '/connect'
      fullPath: '/$teamId/setup/integration/$identifier/connect'
      preLoaderRoute: typeof AppTeamIdSetupIntegrationIdentifierConnectImport
      parentRoute: typeof AppTeamIdSetupIntegrationIdentifierRouteImport
    }
    '/_app/$teamId/setup/integration/$identifier/test': {
      id: '/_app/$teamId/setup/integration/$identifier/test'
      path: '/test'
      fullPath: '/$teamId/setup/integration/$identifier/test'
      preLoaderRoute: typeof AppTeamIdSetupIntegrationIdentifierTestImport
      parentRoute: typeof AppTeamIdSetupIntegrationIdentifierRouteImport
    }
    '/_app/$teamId/setup/_setup/extension/': {
      id: '/_app/$teamId/setup/_setup/extension/'
      path: '/extension'
      fullPath: '/$teamId/setup/extension'
      preLoaderRoute: typeof AppTeamIdSetupSetupExtensionIndexImport
      parentRoute: typeof AppTeamIdSetupSetupRouteImport
    }
  }
}

// Create and export the route tree

interface AppTeamIdDashboardRouteRouteChildren {
  AppTeamIdDashboardAccountSettingsRoute: typeof AppTeamIdDashboardAccountSettingsRoute
  AppTeamIdDashboardHomeIndexRoute: typeof AppTeamIdDashboardHomeIndexRoute
  AppTeamIdDashboardIntegrationsIndexRoute: typeof AppTeamIdDashboardIntegrationsIndexRoute
  AppTeamIdDashboardSubscriptionIndexRoute: typeof AppTeamIdDashboardSubscriptionIndexRoute
  AppTeamIdDashboardTeamIndexRoute: typeof AppTeamIdDashboardTeamIndexRoute
  AppTeamIdDashboardHomeFolderFolderIdRoute: typeof AppTeamIdDashboardHomeFolderFolderIdRoute
  AppTeamIdDashboardIntegrationsIdentifierEditRoute: typeof AppTeamIdDashboardIntegrationsIdentifierEditRoute
}

const AppTeamIdDashboardRouteRouteChildren: AppTeamIdDashboardRouteRouteChildren =
  {
    AppTeamIdDashboardAccountSettingsRoute:
      AppTeamIdDashboardAccountSettingsRoute,
    AppTeamIdDashboardHomeIndexRoute: AppTeamIdDashboardHomeIndexRoute,
    AppTeamIdDashboardIntegrationsIndexRoute:
      AppTeamIdDashboardIntegrationsIndexRoute,
    AppTeamIdDashboardSubscriptionIndexRoute:
      AppTeamIdDashboardSubscriptionIndexRoute,
    AppTeamIdDashboardTeamIndexRoute: AppTeamIdDashboardTeamIndexRoute,
    AppTeamIdDashboardHomeFolderFolderIdRoute:
      AppTeamIdDashboardHomeFolderFolderIdRoute,
    AppTeamIdDashboardIntegrationsIdentifierEditRoute:
      AppTeamIdDashboardIntegrationsIdentifierEditRoute,
  }

const AppTeamIdDashboardRouteRouteWithChildren =
  AppTeamIdDashboardRouteRoute._addFileChildren(
    AppTeamIdDashboardRouteRouteChildren,
  )

interface AppTeamIdSetupSetupRouteRouteChildren {
  AppTeamIdSetupSetupEmbedRoute: typeof AppTeamIdSetupSetupEmbedRoute
  AppTeamIdSetupSetupEmbedTestRoute: typeof AppTeamIdSetupSetupEmbedTestRoute
  AppTeamIdSetupSetupInstallRoute: typeof AppTeamIdSetupSetupInstallRoute
  AppTeamIdSetupSetupProfileRoute: typeof AppTeamIdSetupSetupProfileRoute
  AppTeamIdSetupSetupIndexRoute: typeof AppTeamIdSetupSetupIndexRoute
  AppTeamIdSetupSetupExtensionCompleteRoute: typeof AppTeamIdSetupSetupExtensionCompleteRoute
  AppTeamIdSetupSetupExtensionIndexRoute: typeof AppTeamIdSetupSetupExtensionIndexRoute
}

const AppTeamIdSetupSetupRouteRouteChildren: AppTeamIdSetupSetupRouteRouteChildren =
  {
    AppTeamIdSetupSetupEmbedRoute: AppTeamIdSetupSetupEmbedRoute,
    AppTeamIdSetupSetupEmbedTestRoute: AppTeamIdSetupSetupEmbedTestRoute,
    AppTeamIdSetupSetupInstallRoute: AppTeamIdSetupSetupInstallRoute,
    AppTeamIdSetupSetupProfileRoute: AppTeamIdSetupSetupProfileRoute,
    AppTeamIdSetupSetupIndexRoute: AppTeamIdSetupSetupIndexRoute,
    AppTeamIdSetupSetupExtensionCompleteRoute:
      AppTeamIdSetupSetupExtensionCompleteRoute,
    AppTeamIdSetupSetupExtensionIndexRoute:
      AppTeamIdSetupSetupExtensionIndexRoute,
  }

const AppTeamIdSetupSetupRouteRouteWithChildren =
  AppTeamIdSetupSetupRouteRoute._addFileChildren(
    AppTeamIdSetupSetupRouteRouteChildren,
  )

interface AppTeamIdSetupIntegrationIdentifierRouteRouteChildren {
  AppTeamIdSetupIntegrationIdentifierIndexRoute: typeof AppTeamIdSetupIntegrationIdentifierIndexRoute
  AppTeamIdSetupIntegrationIdentifierConnectRoute: typeof AppTeamIdSetupIntegrationIdentifierConnectRoute
  AppTeamIdSetupIntegrationIdentifierTestRoute: typeof AppTeamIdSetupIntegrationIdentifierTestRoute
}

const AppTeamIdSetupIntegrationIdentifierRouteRouteChildren: AppTeamIdSetupIntegrationIdentifierRouteRouteChildren =
  {
    AppTeamIdSetupIntegrationIdentifierIndexRoute:
      AppTeamIdSetupIntegrationIdentifierIndexRoute,
    AppTeamIdSetupIntegrationIdentifierConnectRoute:
      AppTeamIdSetupIntegrationIdentifierConnectRoute,
    AppTeamIdSetupIntegrationIdentifierTestRoute:
      AppTeamIdSetupIntegrationIdentifierTestRoute,
  }

const AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren =
  AppTeamIdSetupIntegrationIdentifierRouteRoute._addFileChildren(
    AppTeamIdSetupIntegrationIdentifierRouteRouteChildren,
  )

interface AppTeamIdSetupRouteChildren {
  AppTeamIdSetupSetupRouteRoute: typeof AppTeamIdSetupSetupRouteRouteWithChildren
  AppTeamIdSetupIntegrationIdentifierRouteRoute: typeof AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren
}

const AppTeamIdSetupRouteChildren: AppTeamIdSetupRouteChildren = {
  AppTeamIdSetupSetupRouteRoute: AppTeamIdSetupSetupRouteRouteWithChildren,
  AppTeamIdSetupIntegrationIdentifierRouteRoute:
    AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren,
}

const AppTeamIdSetupRouteWithChildren = AppTeamIdSetupRoute._addFileChildren(
  AppTeamIdSetupRouteChildren,
)

interface AppTeamIdRouteRouteChildren {
  AppTeamIdDashboardRouteRoute: typeof AppTeamIdDashboardRouteRouteWithChildren
  AppTeamIdSwitchTeamRoute: typeof AppTeamIdSwitchTeamRoute
  AppTeamIdSetupRoute: typeof AppTeamIdSetupRouteWithChildren
}

const AppTeamIdRouteRouteChildren: AppTeamIdRouteRouteChildren = {
  AppTeamIdDashboardRouteRoute: AppTeamIdDashboardRouteRouteWithChildren,
  AppTeamIdSwitchTeamRoute: AppTeamIdSwitchTeamRoute,
  AppTeamIdSetupRoute: AppTeamIdSetupRouteWithChildren,
}

const AppTeamIdRouteRouteWithChildren = AppTeamIdRouteRoute._addFileChildren(
  AppTeamIdRouteRouteChildren,
)

interface AppRShareIdRouteRouteChildren {
  AppRShareIdInspectRoute: typeof AppRShareIdInspectRoute
  AppRShareIdTimelineRoute: typeof AppRShareIdTimelineRoute
  AppRShareIdIndexRoute: typeof AppRShareIdIndexRoute
  AppRShareIdNetworkEventIdRoute: typeof AppRShareIdNetworkEventIdRoute
  AppRShareIdNetworkIndexRoute: typeof AppRShareIdNetworkIndexRoute
}

const AppRShareIdRouteRouteChildren: AppRShareIdRouteRouteChildren = {
  AppRShareIdInspectRoute: AppRShareIdInspectRoute,
  AppRShareIdTimelineRoute: AppRShareIdTimelineRoute,
  AppRShareIdIndexRoute: AppRShareIdIndexRoute,
  AppRShareIdNetworkEventIdRoute: AppRShareIdNetworkEventIdRoute,
  AppRShareIdNetworkIndexRoute: AppRShareIdNetworkIndexRoute,
}

const AppRShareIdRouteRouteWithChildren =
  AppRShareIdRouteRoute._addFileChildren(AppRShareIdRouteRouteChildren)

interface AppRouteRouteChildren {
  AppTeamIdRouteRoute: typeof AppTeamIdRouteRouteWithChildren
  AppRShareIdRouteRoute: typeof AppRShareIdRouteRouteWithChildren
  AppAuthLoginRoute: typeof AppAuthLoginRoute
  AppExtensionPostInstallRoute: typeof AppExtensionPostInstallRoute
  AppTSplatRoute: typeof AppTSplatRoute
}

const AppRouteRouteChildren: AppRouteRouteChildren = {
  AppTeamIdRouteRoute: AppTeamIdRouteRouteWithChildren,
  AppRShareIdRouteRoute: AppRShareIdRouteRouteWithChildren,
  AppAuthLoginRoute: AppAuthLoginRoute,
  AppExtensionPostInstallRoute: AppExtensionPostInstallRoute,
  AppTSplatRoute: AppTSplatRoute,
}

const AppRouteRouteWithChildren = AppRouteRoute._addFileChildren(
  AppRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AppRouteRouteWithChildren
  '/test': typeof TestRoute
  '/$teamId': typeof AppTeamIdDashboardRouteRouteWithChildren
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/preview': typeof WidgetPreviewRoute
  '/r/$shareId': typeof AppRShareIdRouteRouteWithChildren
  '/$teamId/switch-team': typeof AppTeamIdSwitchTeamRoute
  '/auth/login': typeof AppAuthLoginRoute
  '/extension/post-install': typeof AppExtensionPostInstallRoute
  '/t/$': typeof AppTSplatRoute
  '/$teamId/setup': typeof AppTeamIdSetupSetupRouteRouteWithChildren
  '/r/$shareId/inspect': typeof AppRShareIdInspectRoute
  '/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/r/$shareId/': typeof AppRShareIdIndexRoute
  '/$teamId/setup/integration/$identifier': typeof AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren
  '/$teamId/account/settings': typeof AppTeamIdDashboardAccountSettingsRoute
  '/$teamId/setup/embed': typeof AppTeamIdSetupSetupEmbedRoute
  '/$teamId/setup/embed-test': typeof AppTeamIdSetupSetupEmbedTestRoute
  '/$teamId/setup/install': typeof AppTeamIdSetupSetupInstallRoute
  '/$teamId/setup/profile': typeof AppTeamIdSetupSetupProfileRoute
  '/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/$teamId/': typeof AppTeamIdDashboardHomeIndexRoute
  '/$teamId/integrations': typeof AppTeamIdDashboardIntegrationsIndexRoute
  '/$teamId/subscription': typeof AppTeamIdDashboardSubscriptionIndexRoute
  '/$teamId/team': typeof AppTeamIdDashboardTeamIndexRoute
  '/$teamId/setup/': typeof AppTeamIdSetupSetupIndexRoute
  '/r/$shareId/network': typeof AppRShareIdNetworkIndexRoute
  '/$teamId/folder/$folderId': typeof AppTeamIdDashboardHomeFolderFolderIdRoute
  '/$teamId/integrations/$identifier/edit': typeof AppTeamIdDashboardIntegrationsIdentifierEditRoute
  '/$teamId/setup/extension/complete': typeof AppTeamIdSetupSetupExtensionCompleteRoute
  '/$teamId/setup/integration/$identifier/$index': typeof AppTeamIdSetupIntegrationIdentifierIndexRoute
  '/$teamId/setup/integration/$identifier/connect': typeof AppTeamIdSetupIntegrationIdentifierConnectRoute
  '/$teamId/setup/integration/$identifier/test': typeof AppTeamIdSetupIntegrationIdentifierTestRoute
  '/$teamId/setup/extension': typeof AppTeamIdSetupSetupExtensionIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AppRouteRouteWithChildren
  '/test': typeof TestRoute
  '/$teamId': typeof AppTeamIdDashboardHomeIndexRoute
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/preview': typeof WidgetPreviewRoute
  '/$teamId/switch-team': typeof AppTeamIdSwitchTeamRoute
  '/auth/login': typeof AppAuthLoginRoute
  '/extension/post-install': typeof AppExtensionPostInstallRoute
  '/t/$': typeof AppTSplatRoute
  '/$teamId/setup': typeof AppTeamIdSetupSetupIndexRoute
  '/r/$shareId/inspect': typeof AppRShareIdInspectRoute
  '/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/r/$shareId': typeof AppRShareIdIndexRoute
  '/$teamId/setup/integration/$identifier': typeof AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren
  '/$teamId/account/settings': typeof AppTeamIdDashboardAccountSettingsRoute
  '/$teamId/setup/embed': typeof AppTeamIdSetupSetupEmbedRoute
  '/$teamId/setup/embed-test': typeof AppTeamIdSetupSetupEmbedTestRoute
  '/$teamId/setup/install': typeof AppTeamIdSetupSetupInstallRoute
  '/$teamId/setup/profile': typeof AppTeamIdSetupSetupProfileRoute
  '/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/$teamId/integrations': typeof AppTeamIdDashboardIntegrationsIndexRoute
  '/$teamId/subscription': typeof AppTeamIdDashboardSubscriptionIndexRoute
  '/$teamId/team': typeof AppTeamIdDashboardTeamIndexRoute
  '/r/$shareId/network': typeof AppRShareIdNetworkIndexRoute
  '/$teamId/folder/$folderId': typeof AppTeamIdDashboardHomeFolderFolderIdRoute
  '/$teamId/integrations/$identifier/edit': typeof AppTeamIdDashboardIntegrationsIdentifierEditRoute
  '/$teamId/setup/extension/complete': typeof AppTeamIdSetupSetupExtensionCompleteRoute
  '/$teamId/setup/integration/$identifier/$index': typeof AppTeamIdSetupIntegrationIdentifierIndexRoute
  '/$teamId/setup/integration/$identifier/connect': typeof AppTeamIdSetupIntegrationIdentifierConnectRoute
  '/$teamId/setup/integration/$identifier/test': typeof AppTeamIdSetupIntegrationIdentifierTestRoute
  '/$teamId/setup/extension': typeof AppTeamIdSetupSetupExtensionIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteRouteWithChildren
  '/test': typeof TestRoute
  '/_app/$teamId': typeof AppTeamIdRouteRouteWithChildren
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/preview': typeof WidgetPreviewRoute
  '/_app/$teamId/_dashboard': typeof AppTeamIdDashboardRouteRouteWithChildren
  '/_app/r/$shareId': typeof AppRShareIdRouteRouteWithChildren
  '/_app/$teamId/switch-team': typeof AppTeamIdSwitchTeamRoute
  '/_app/auth/login': typeof AppAuthLoginRoute
  '/_app/extension/post-install': typeof AppExtensionPostInstallRoute
  '/_app/t/$': typeof AppTSplatRoute
  '/_app/$teamId/setup': typeof AppTeamIdSetupRouteWithChildren
  '/_app/$teamId/setup/_setup': typeof AppTeamIdSetupSetupRouteRouteWithChildren
  '/_app/r/$shareId/inspect': typeof AppRShareIdInspectRoute
  '/_app/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/_app/r/$shareId/': typeof AppRShareIdIndexRoute
  '/_app/$teamId/setup/integration/$identifier': typeof AppTeamIdSetupIntegrationIdentifierRouteRouteWithChildren
  '/_app/$teamId/_dashboard/account/settings': typeof AppTeamIdDashboardAccountSettingsRoute
  '/_app/$teamId/setup/_setup/embed': typeof AppTeamIdSetupSetupEmbedRoute
  '/_app/$teamId/setup/_setup/embed-test': typeof AppTeamIdSetupSetupEmbedTestRoute
  '/_app/$teamId/setup/_setup/install': typeof AppTeamIdSetupSetupInstallRoute
  '/_app/$teamId/setup/_setup/profile': typeof AppTeamIdSetupSetupProfileRoute
  '/_app/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/_app/$teamId/_dashboard/_home/': typeof AppTeamIdDashboardHomeIndexRoute
  '/_app/$teamId/_dashboard/integrations/': typeof AppTeamIdDashboardIntegrationsIndexRoute
  '/_app/$teamId/_dashboard/subscription/': typeof AppTeamIdDashboardSubscriptionIndexRoute
  '/_app/$teamId/_dashboard/team/': typeof AppTeamIdDashboardTeamIndexRoute
  '/_app/$teamId/setup/_setup/': typeof AppTeamIdSetupSetupIndexRoute
  '/_app/r/$shareId/network/': typeof AppRShareIdNetworkIndexRoute
  '/_app/$teamId/_dashboard/_home/folder/$folderId': typeof AppTeamIdDashboardHomeFolderFolderIdRoute
  '/_app/$teamId/_dashboard/integrations/$identifier/edit': typeof AppTeamIdDashboardIntegrationsIdentifierEditRoute
  '/_app/$teamId/setup/_setup/extension/complete': typeof AppTeamIdSetupSetupExtensionCompleteRoute
  '/_app/$teamId/setup/integration/$identifier/$index': typeof AppTeamIdSetupIntegrationIdentifierIndexRoute
  '/_app/$teamId/setup/integration/$identifier/connect': typeof AppTeamIdSetupIntegrationIdentifierConnectRoute
  '/_app/$teamId/setup/integration/$identifier/test': typeof AppTeamIdSetupIntegrationIdentifierTestRoute
  '/_app/$teamId/setup/_setup/extension/': typeof AppTeamIdSetupSetupExtensionIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/test'
    | '/$teamId'
    | '/invitation/$membershipId'
    | '/widget/preview'
    | '/r/$shareId'
    | '/$teamId/switch-team'
    | '/auth/login'
    | '/extension/post-install'
    | '/t/$'
    | '/$teamId/setup'
    | '/r/$shareId/inspect'
    | '/r/$shareId/timeline'
    | '/r/$shareId/'
    | '/$teamId/setup/integration/$identifier'
    | '/$teamId/account/settings'
    | '/$teamId/setup/embed'
    | '/$teamId/setup/embed-test'
    | '/$teamId/setup/install'
    | '/$teamId/setup/profile'
    | '/r/$shareId/network/$eventId'
    | '/$teamId/'
    | '/$teamId/integrations'
    | '/$teamId/subscription'
    | '/$teamId/team'
    | '/$teamId/setup/'
    | '/r/$shareId/network'
    | '/$teamId/folder/$folderId'
    | '/$teamId/integrations/$identifier/edit'
    | '/$teamId/setup/extension/complete'
    | '/$teamId/setup/integration/$identifier/$index'
    | '/$teamId/setup/integration/$identifier/connect'
    | '/$teamId/setup/integration/$identifier/test'
    | '/$teamId/setup/extension'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/test'
    | '/$teamId'
    | '/invitation/$membershipId'
    | '/widget/preview'
    | '/$teamId/switch-team'
    | '/auth/login'
    | '/extension/post-install'
    | '/t/$'
    | '/$teamId/setup'
    | '/r/$shareId/inspect'
    | '/r/$shareId/timeline'
    | '/r/$shareId'
    | '/$teamId/setup/integration/$identifier'
    | '/$teamId/account/settings'
    | '/$teamId/setup/embed'
    | '/$teamId/setup/embed-test'
    | '/$teamId/setup/install'
    | '/$teamId/setup/profile'
    | '/r/$shareId/network/$eventId'
    | '/$teamId/integrations'
    | '/$teamId/subscription'
    | '/$teamId/team'
    | '/r/$shareId/network'
    | '/$teamId/folder/$folderId'
    | '/$teamId/integrations/$identifier/edit'
    | '/$teamId/setup/extension/complete'
    | '/$teamId/setup/integration/$identifier/$index'
    | '/$teamId/setup/integration/$identifier/connect'
    | '/$teamId/setup/integration/$identifier/test'
    | '/$teamId/setup/extension'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/test'
    | '/_app/$teamId'
    | '/invitation/$membershipId'
    | '/widget/preview'
    | '/_app/$teamId/_dashboard'
    | '/_app/r/$shareId'
    | '/_app/$teamId/switch-team'
    | '/_app/auth/login'
    | '/_app/extension/post-install'
    | '/_app/t/$'
    | '/_app/$teamId/setup'
    | '/_app/$teamId/setup/_setup'
    | '/_app/r/$shareId/inspect'
    | '/_app/r/$shareId/timeline'
    | '/_app/r/$shareId/'
    | '/_app/$teamId/setup/integration/$identifier'
    | '/_app/$teamId/_dashboard/account/settings'
    | '/_app/$teamId/setup/_setup/embed'
    | '/_app/$teamId/setup/_setup/embed-test'
    | '/_app/$teamId/setup/_setup/install'
    | '/_app/$teamId/setup/_setup/profile'
    | '/_app/r/$shareId/network/$eventId'
    | '/_app/$teamId/_dashboard/_home/'
    | '/_app/$teamId/_dashboard/integrations/'
    | '/_app/$teamId/_dashboard/subscription/'
    | '/_app/$teamId/_dashboard/team/'
    | '/_app/$teamId/setup/_setup/'
    | '/_app/r/$shareId/network/'
    | '/_app/$teamId/_dashboard/_home/folder/$folderId'
    | '/_app/$teamId/_dashboard/integrations/$identifier/edit'
    | '/_app/$teamId/setup/_setup/extension/complete'
    | '/_app/$teamId/setup/integration/$identifier/$index'
    | '/_app/$teamId/setup/integration/$identifier/connect'
    | '/_app/$teamId/setup/integration/$identifier/test'
    | '/_app/$teamId/setup/_setup/extension/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRouteRoute: typeof AppRouteRouteWithChildren
  TestRoute: typeof TestRoute
  InvitationMembershipIdRoute: typeof InvitationMembershipIdRoute
  WidgetPreviewRoute: typeof WidgetPreviewRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRouteRoute: AppRouteRouteWithChildren,
  TestRoute: TestRoute,
  InvitationMembershipIdRoute: InvitationMembershipIdRoute,
  WidgetPreviewRoute: WidgetPreviewRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/test",
        "/invitation/$membershipId",
        "/widget/preview"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app/route.tsx",
      "children": [
        "/_app/$teamId",
        "/_app/r/$shareId",
        "/_app/auth/login",
        "/_app/extension/post-install",
        "/_app/t/$"
      ]
    },
    "/test": {
      "filePath": "test.tsx"
    },
    "/_app/$teamId": {
      "filePath": "_app/$teamId/route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/$teamId/_dashboard",
        "/_app/$teamId/switch-team",
        "/_app/$teamId/setup"
      ]
    },
    "/invitation/$membershipId": {
      "filePath": "invitation/$membershipId.tsx"
    },
    "/widget/preview": {
      "filePath": "widget/preview.tsx"
    },
    "/_app/$teamId/_dashboard": {
      "filePath": "_app/$teamId/_dashboard/route.tsx",
      "parent": "/_app/$teamId",
      "children": [
        "/_app/$teamId/_dashboard/account/settings",
        "/_app/$teamId/_dashboard/_home/",
        "/_app/$teamId/_dashboard/integrations/",
        "/_app/$teamId/_dashboard/subscription/",
        "/_app/$teamId/_dashboard/team/",
        "/_app/$teamId/_dashboard/_home/folder/$folderId",
        "/_app/$teamId/_dashboard/integrations/$identifier/edit"
      ]
    },
    "/_app/r/$shareId": {
      "filePath": "_app/r/$shareId/route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/r/$shareId/inspect",
        "/_app/r/$shareId/timeline",
        "/_app/r/$shareId/",
        "/_app/r/$shareId/network/$eventId",
        "/_app/r/$shareId/network/"
      ]
    },
    "/_app/$teamId/switch-team": {
      "filePath": "_app/$teamId/switch-team.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/auth/login": {
      "filePath": "_app/auth/login.tsx",
      "parent": "/_app"
    },
    "/_app/extension/post-install": {
      "filePath": "_app/extension/post-install.tsx",
      "parent": "/_app"
    },
    "/_app/t/$": {
      "filePath": "_app/t/$.tsx",
      "parent": "/_app"
    },
    "/_app/$teamId/setup": {
      "filePath": "_app/$teamId/setup/_setup",
      "parent": "/_app/$teamId",
      "children": [
        "/_app/$teamId/setup/_setup",
        "/_app/$teamId/setup/integration/$identifier"
      ]
    },
    "/_app/$teamId/setup/_setup": {
      "filePath": "_app/$teamId/setup/_setup/route.tsx",
      "parent": "/_app/$teamId/setup",
      "children": [
        "/_app/$teamId/setup/_setup/embed",
        "/_app/$teamId/setup/_setup/embed-test",
        "/_app/$teamId/setup/_setup/install",
        "/_app/$teamId/setup/_setup/profile",
        "/_app/$teamId/setup/_setup/",
        "/_app/$teamId/setup/_setup/extension/complete",
        "/_app/$teamId/setup/_setup/extension/"
      ]
    },
    "/_app/r/$shareId/inspect": {
      "filePath": "_app/r/$shareId/inspect.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/r/$shareId/timeline": {
      "filePath": "_app/r/$shareId/timeline.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/r/$shareId/": {
      "filePath": "_app/r/$shareId/index.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/setup/integration/$identifier": {
      "filePath": "_app/$teamId/setup/integration/$identifier/route.tsx",
      "parent": "/_app/$teamId/setup",
      "children": [
        "/_app/$teamId/setup/integration/$identifier/$index",
        "/_app/$teamId/setup/integration/$identifier/connect",
        "/_app/$teamId/setup/integration/$identifier/test"
      ]
    },
    "/_app/$teamId/_dashboard/account/settings": {
      "filePath": "_app/$teamId/_dashboard/account/settings.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/setup/_setup/embed": {
      "filePath": "_app/$teamId/setup/_setup/embed.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/$teamId/setup/_setup/embed-test": {
      "filePath": "_app/$teamId/setup/_setup/embed-test.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/$teamId/setup/_setup/install": {
      "filePath": "_app/$teamId/setup/_setup/install.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/$teamId/setup/_setup/profile": {
      "filePath": "_app/$teamId/setup/_setup/profile.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/r/$shareId/network/$eventId": {
      "filePath": "_app/r/$shareId/network.$eventId.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/_dashboard/_home/": {
      "filePath": "_app/$teamId/_dashboard/_home/index.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/_dashboard/integrations/": {
      "filePath": "_app/$teamId/_dashboard/integrations/index.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/_dashboard/subscription/": {
      "filePath": "_app/$teamId/_dashboard/subscription/index.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/_dashboard/team/": {
      "filePath": "_app/$teamId/_dashboard/team/index.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/setup/_setup/": {
      "filePath": "_app/$teamId/setup/_setup/index.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/r/$shareId/network/": {
      "filePath": "_app/r/$shareId/network.index.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/_dashboard/_home/folder/$folderId": {
      "filePath": "_app/$teamId/_dashboard/_home/folder/$folderId.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/_dashboard/integrations/$identifier/edit": {
      "filePath": "_app/$teamId/_dashboard/integrations/$identifier/edit.tsx",
      "parent": "/_app/$teamId/_dashboard"
    },
    "/_app/$teamId/setup/_setup/extension/complete": {
      "filePath": "_app/$teamId/setup/_setup/extension/complete.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    },
    "/_app/$teamId/setup/integration/$identifier/$index": {
      "filePath": "_app/$teamId/setup/integration/$identifier/$index.tsx",
      "parent": "/_app/$teamId/setup/integration/$identifier"
    },
    "/_app/$teamId/setup/integration/$identifier/connect": {
      "filePath": "_app/$teamId/setup/integration/$identifier/connect.tsx",
      "parent": "/_app/$teamId/setup/integration/$identifier"
    },
    "/_app/$teamId/setup/integration/$identifier/test": {
      "filePath": "_app/$teamId/setup/integration/$identifier/test.tsx",
      "parent": "/_app/$teamId/setup/integration/$identifier"
    },
    "/_app/$teamId/setup/_setup/extension/": {
      "filePath": "_app/$teamId/setup/_setup/extension/index.tsx",
      "parent": "/_app/$teamId/setup/_setup"
    }
  }
}
ROUTE_MANIFEST_END */
