import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { PageLoader } from "~features/page-loader/page-loader.js";
import SubscriptionPage from "~features/subscription-page/subscription-page.js";
import { RequireRole } from "~features/teams/require-role.js";

const SubscriptionSearchParams = z.object({
  success: z.boolean().optional(),
});
type SubscriptionSearchParams = z.infer<typeof SubscriptionSearchParams>;

export const Route = createFileRoute("/_app/$teamId/_dashboard/subscription/")({
  pendingComponent: PageLoader,
  validateSearch: (search): SubscriptionSearchParams => {
    return SubscriptionSearchParams.parse(search);
  },
  component: () => {
    return (
      <RequireRole role="admin">
        <SubscriptionPage />
      </RequireRole>
    );
  },
});
