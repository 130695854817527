import { getDisplayMedia } from "./get-display-media.js";
export const takeScreenshot = async () => {
    const stream = await getDisplayMedia();
    try {
        const video = document.createElement("video");
        video.width = window.innerWidth * window.devicePixelRatio;
        video.height = window.innerHeight * window.devicePixelRatio;
        video.srcObject = stream;
        video.autoplay = true;
        await new Promise((resolve, reject) => {
            video.onloadeddata = resolve;
            video.onerror = reject;
        });
        const videoTrackSettings = video.srcObject?.getTracks()[0].getSettings();
        // Create a canvas with the video's size and draw the video frame on it
        const canvas = document.createElement("canvas");
        const width = videoTrackSettings?.width ?? 0;
        const height = videoTrackSettings?.height ?? 0;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx?.drawImage(video, 0, 0);
        const image = canvas.toDataURL("image/png", 100);
        stream.getTracks().forEach((track) => track.stop());
        return image;
    }
    catch (error) {
        stream.getTracks().forEach((track) => track.stop());
        throw error;
    }
};
