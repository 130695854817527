import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { ExtensionComplete } from "~features/setup/extension-complete/extension-complete.js";

const SearchParams = z.object({
  reportUrl: z.string(),
});
type SearchParams = z.infer<typeof SearchParams>;

export const Route = createFileRoute("/_app/$teamId/setup/_setup/extension/complete")({
  validateSearch: (params): SearchParams => {
    return SearchParams.parse(params);
  },
  component: ExtensionComplete,
});
