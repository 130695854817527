import { createFileRoute, Outlet, useParams } from "@tanstack/react-router";

import { RequireAuth } from "~features/auth/require-auth.js";
import { FetchTeamProvider } from "~features/teams/fetch-team-provider.js";
import { RequireTeam } from "~features/teams/require-team.js";

const Component = () => {
  const { teamId } = useParams({ from: "/_app/$teamId" });
  return (
    <RequireAuth teamId={teamId}>
      <FetchTeamProvider teamId={teamId}>
        <RequireTeam>
          <Outlet />
        </RequireTeam>
      </FetchTeamProvider>
    </RequireAuth>
  );
};

export const Route = createFileRoute("/_app/$teamId")({
  component: Component,
});
