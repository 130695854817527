import { createFileRoute } from "@tanstack/react-router";

import { EditIntegrationPage } from "~features/integrations/edit-integration-page/edit-integration-page.js";
import { PageLoader } from "~features/page-loader/page-loader.js";

export const Route = createFileRoute(
  "/_app/$teamId/_dashboard/integrations/$identifier/edit"
)({
  pendingComponent: PageLoader,
  component: EditIntegrationPage,
});
