import { cx } from "@emotion/css";
import type React from "react";

import { Spinner } from "../spinner/spinner.js";

import * as styles from "./button.styles";

import type { ThemeColor } from "~styles/constants.js";
import { color } from "~styles/constants.js";

export type ButtonVariant =
  | "brand"
  | "contrast"
  | "secondary"
  | "subtle"
  | "complementary"
  | "critical"
  | "criticalSubtle"
  | "criticalSecondary"
  | "criticalMuted"
  | "muted";

export type UseButtonProps<Extra> = {
  size?: "xs" | "sm" | "md" | "lg";
  variant?: ButtonVariant;
  align?: "left" | "center" | "right";
  color?: ThemeColor;
  isLoading?: boolean;
  iconOnly?: boolean;
  isRound?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  shouldFillContainer?: boolean;
  className?: string;
  children?: React.ReactNode;
  loadingText?: string;
  currentColor?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (event: React.MouseEvent<any>) => void;
} & Extra;

export const useButton = <Extra,>({
  align = "center",
  variant = "contrast",
  isLoading,
  iconOnly,
  isRound,
  isSelected,
  isDisabled,
  shouldFillContainer,
  size = "md",
  onClick,
  children,
  className,
  loadingText,
  color: colorOverride,
  currentColor,
  ...rest
}: UseButtonProps<Extra>) => {
  return {
    ...rest,
    disabled: isDisabled,
    onClick: isDisabled ? undefined : onClick,
    className: cx(
      styles.button,
      styles[size],
      styles[variant],
      styles[align],

      {
        [styles.isRound]: isRound,
        [styles.iconOnly]: iconOnly,
        [styles.fillContainer]: shouldFillContainer,
        [styles.selected]: isSelected,
        [styles.currentColor]: currentColor,
      },
      className
    ),
    children: (
      <>
        <span
          className={cx(styles.content, { [styles.loading]: isLoading })}
          style={{ color: colorOverride ? color(colorOverride) : undefined }}
        >
          {children}
        </span>
        {isLoading ? (
          <>
            <span className={styles.spinner}>
              <Spinner />
              {loadingText}
            </span>
          </>
        ) : null}
      </>
    ),
  };
};
