import { createFileRoute } from "@tanstack/react-router";

import { AuthProvider } from "~features/auth/auth-provider.js";
import ReportViewer from "~features/report-viewer/report-viewer.js";
import { ReportViewerSkeleton } from "~features/report-viewer/report-viewer.skeleton.js";

export const Route = createFileRoute("/_app/r/$shareId")({
  pendingComponent: ReportViewerSkeleton,
  component: () => {
    return (
      <AuthProvider>
        <ReportViewer />
      </AuthProvider>
    );
  },
});
