import { createFileRoute } from "@tanstack/react-router";

import { AllCapturesPage } from "~features/all-captures-page/all-captures-page.js";
import { AllCapturesPageLoading } from "~features/all-captures-page/all-captures-page.loading.js";
import { HomeLayoutUpgradeView } from "~features/home-layout/home-layout-upgrade-view.js";
import { RequireSubscription } from "~features/teams/require-subscription.js";

export const Route = createFileRoute("/_app/$teamId/_dashboard/_home/")({
  pendingComponent: AllCapturesPageLoading,
  component: () => {
    return (
      <RequireSubscription fallback={<HomeLayoutUpgradeView />}>
        <AllCapturesPage />
      </RequireSubscription>
    );
  },
});
