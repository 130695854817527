export const getDisplayMedia = () => navigator.mediaDevices.getDisplayMedia({
    //@ts-expect-error -- Not supported in this version of typescript
    selfBrowserSurface: "include",
    preferCurrentTab: true,
    video: {
        displaySurface: "browser",
        frameRate: { ideal: 60, max: 60 },
        width: { max: 3840 },
        height: { max: 2160 },
    },
});
