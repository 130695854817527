import chroma from "chroma-js";

const brand = chroma("14FC84");

const tint = "#2e6ffc";

const greyScale = chroma.scale(["#ffffff", "#111111"]);

const primary = greyScale(0);
const secondary = greyScale(0.03).mix(tint, 0.01);
const tertiary = greyScale(0.015).mix(tint, 0.01);
const contrast = greyScale(1);

const accent = "#BDEE63";
const complementary = chroma("#2e6ffc");

const muted = greyScale(0.2).mix(tint, 0.1).alpha(0.2);
const overlay = "#00000080";

const backgroundSkeleton = "#e8ebf0";

const success = chroma("#3E9B4F");
const warning = chroma("#f59e0c");
const critical = chroma("#dc2625");

const textWarning = chroma("#d9760c");

export const lightColors = {
  brand: brand.hex(),
  textOnBrand: brand.darken(5).hex(),
  brandHover: brand.brighten(0.4).hex(),
  brandLight: brand.alpha(0.8).hex(),
  primary: primary.hex(),
  primaryHover: primary.darken(0.1).hex(),
  primaryBorder: primary.darken(0.4).hex(),
  secondary: secondary.hex(),
  secondaryHover: secondary.darken(0.2).hex(),
  tertiary: tertiary.hex(),
  tertiaryHover: tertiary.darken(0.1).hex(),
  tertiaryBorder: primary.darken(0.4).hex(),
  secondaryBorder: secondary.darken(0.4).hex(),
  scrollbar: greyScale(0.2).mix(tint, 0.1).alpha(0.4).hex(),
  muted: muted.hex(),
  mutedHover: muted.darken(0.5).hex(),
  overlay,
  textPrimary: greyScale(1).hex(),
  textSecondary: greyScale(0.6).mix(tint, 0.1).hex(),
  textInverse: greyScale(0).hex(),
  textInverseSecondary: greyScale(0.2).hex(),
  textPlaceholder: greyScale(0.4).mix(tint, 0.1).hex(),
  textDisabled: greyScale(0.2).hex(),
  accent,
  complementary: complementary.hex(),
  complementaryHover: complementary.brighten(0.2).hex(),
  textOnComplementary: "#ffffff",
  success: success.hex(),
  textOnSuccess: greyScale(1).hex(),
  successMuted: success.alpha(0.1).hex(),
  textSuccess: success.darken(0.8).hex(),
  contrast: contrast.hex(),
  contrastHover: contrast.brighten(0.4).hex(),
  backgroundSkeleton,
  warning: warning.hex(),
  warningMuted: warning.alpha(0.1).hex(),
  warningMutedHover: warning.alpha(0.2).hex(),
  textWarning: textWarning.hex(),
  textWarningSecondary: textWarning.brighten(0.8).hex(),
  critical: critical.hex(),
  textOnCritical: "#ffffff",
  criticalMuted: critical.alpha(0.1).hex(),
  criticalMutedHover: critical.alpha(0.15).hex(),
  criticalHover: critical.darken(0.2).hex(),
  criticalMutedBorder: critical.alpha(0.3).hex(),
  textCritical: critical.darken(0.5).hex(),
  textCriticalSecondary: critical.brighten(0.8).hex(),

  codeTag: "#AE97BB",
  codeName: "#881280",
  codeKey: "#994400",
  codeValue: "#3646B6",
  codeComment: "#317632",
  codeDocType: "#C0C0C0",

  objectLabel: "#444444",
  objectText: "#8E8E8E",
  objectKey: "#881280",
  objectValue: "#8E8E8E",
  objectString: "#CF4B49",
  objectRegex: "#CF4B49",
  objectError: "#FF0000",
  objectBoolean: "#3646B6",
  objectNumber: "#2D0BC9",
  objectUndefined: "#444444",
  objectNull: "#444444",
  objectPrototype: "#BD84C2",
  objectFunction: "#303942",
  objectFunctionDecorator: "#3646B6",

  iconRequest: "#DB732E",
  iconStyle: "#A314ED",
  iconScript: "#E49562",
  iconFont: "#387D9E",
  iconMedia: "#44A647",
  iconDocument: "#737EF3",
} satisfies Record<string, string>;
