import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Suspense, useState } from "react";

import { DarkModeProvider } from "~features/dark-mode/dark-mode-provider.js";
import { ErrorBoundary } from "~features/error-boundary/error-boundary.js";
import { GlobalLoader } from "~features/global-loader/global-loader.js";
import { PageError } from "~features/page-error/page-error.js";
import { GlobalStyles } from "~styles/global-styles.js";
import { AmplitudeProvider } from "~utils/amplitude-provider.js";

import "~utils/capture-client.js";

export const Route = createRootRoute({
  component: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [queryClient] = useState(() => new QueryClient());
    return (
      <>
        <GlobalStyles />
        <ErrorBoundary
          renderFallback={({ resetErrorBoundary }) => (
            <PageError resetErrorBoundary={resetErrorBoundary} />
          )}
        >
          <DarkModeProvider>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<GlobalLoader />}>
                <AmplitudeProvider enableSessionReplay>
                  <Outlet />
                </AmplitudeProvider>
              </Suspense>
            </QueryClientProvider>
          </DarkModeProvider>
        </ErrorBoundary>
      </>
    );
  },
});
