import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { LoginPage } from "~features/login/login-page/login-page.js";

const LoginSearchParams = z.object({
  membershipId: z.string().optional(),
});
type LoginSearchParams = z.infer<typeof LoginSearchParams>;

export const Route = createFileRoute("/_app/auth/login")({
  validateSearch: (search): LoginSearchParams => {
    return LoginSearchParams.parse(search);
  },
  component: LoginPage,
});
